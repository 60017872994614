import Spinner from "../spinners/Spinner";
const LoadingPage = () => {
  return (
    <div className="fixed top-0 left-0 w-full h-screen z-50 bg-gray-700 overflow-hidden show-animation">
      <div
        className="fixed z-10 top-[50%] left-[50%] max-h-[80vh] max-w-[80%] w-fit translate-y-[-50%] translate-x-[-50%] bg-mainColor shadow-lg rounded-2xl p-10"
        style={{ overflow: "unset" }}
      >
        <Spinner />
      </div>
    </div>
  );
};
export default LoadingPage;
