import { library } from "@fortawesome/fontawesome-svg-core";

import {
    faBars, faShoppingCart,
    faTimes

} from "@fortawesome/free-solid-svg-icons";

import {

} from "@fortawesome/free-regular-svg-icons";

library.add(
    faTimes,faBars,faTimes,faShoppingCart
);
