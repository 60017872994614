import React, {useEffect} from "react";
import {Route, Routes, Outlet, useLocation} from "react-router-dom";
import {lazyImport} from "../utils/lazyImport";
import LoadingPage from "../components/LoadingPage/LoadingPage";
const { MainLayout } = lazyImport(() => import("../components/layout"), "MainLayout");
const { Dashboard } = lazyImport(() => import("../features/dashboard/Dashboard"), "Dashboard");
const { Contact } = lazyImport(() => import("../features/contact/Contact"), "Contact");
const { DigitalPrint } = lazyImport(() => import("../features/digital-print/DigitalPrint"), "DigitalPrint");
const { NotFoundPage } = lazyImport(() => import("../routes/errorPage/NotFoundPage"), "NotFoundPage");
const { UvPrint } = lazyImport(() => import("../features/uv-print/UvPrint"), "UvPrint");
const { DtfPrint } = lazyImport(() => import("../features/dtf-print/DtfPrint"), "DtfPrint");
const { Products } = lazyImport(() => import("../features/products/Products"), "Products");
const { BuyProduct } = lazyImport(() => import("../features/buyProduct/BuyProduct"), "BuyProduct");
const { MarketingMaterial } = lazyImport(() => import("../features/marketingMaterial/MarketingMaterial"), "MarketingMaterial");
const { Company } = lazyImport(() => import("../features/company/Company"), "Company");
const { LaserskoGraviranje } = lazyImport(() => import("../features/laserskoGraviranje/LaserskoGraviranje"), "LaserskoGraviranje");
const { BoxWine } = lazyImport(() => import("../features/boxWine/BoxWine"), "BoxWine");
const { UvColor } = lazyImport(() => import("../features/uvColor/UvColor"), "UvColor");

const Layout = () => {
    return (
        <MainLayout>
            <React.Suspense fallback={<LoadingPage />}>
                <Outlet />
            </React.Suspense>
        </MainLayout>
    );
};
export const AppRoutes = () => {

  const location = useLocation()

  useEffect(() => {
     window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, [location.pathname]);

  return (
      <Routes>
        <Route path="" element={<Layout />}>
            <Route index element={<Dashboard />} />
            <Route path={"kontakt"} element={<Contact />} />
            <Route path={"digitalni-tisk"} element={<DigitalPrint />} />
            <Route path={"uv-tisk"} element={<UvPrint />} />
            <Route path={"dtf-tisk"} element={<DtfPrint />} />
            <Route path={"promocijsko-gradivo"} element={<MarketingMaterial />} />
            <Route path={"izdelki"} element={<Products />} />
            <Route path={"naruci-tisk"} element={<BuyProduct />} />\
            <Route path={"podjetje"} element={<Company />} />
            <Route path={"lasersko-graviranje"} element={<LaserskoGraviranje />} />
            <Route path={"skatle-za-vino"} element={<BoxWine />} />
            <Route path={"uv-color-tisk"} element={<UvColor />} />
        </Route>
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
  );
};
