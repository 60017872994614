import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import {Cookie} from "../components/layout/components/Cookie";
import LoadingPage from "../components/LoadingPage/LoadingPage";
import {Toaster} from "react-hot-toast";

type ProviderProps = {
  children: React.ReactNode;
};
const ErrorFallback = () => {
  return (
    <h1>
      Ooops, something went wrong :(
    </h1>
  );
};
const errorHandler = (error: Error) => {
  console.log("from error boundary: ", error);
};
export const AppProvider = ({ children }: ProviderProps) => {

  return (
      <React.Suspense fallback={<LoadingPage />}>
      <ErrorBoundary FallbackComponent={ErrorFallback} onError={errorHandler}>
        <Router>{children}</Router>

      </ErrorBoundary>
      <Toaster
          position="top-center"
          reverseOrder={false}
      />
      {/*<Cookie />*/}
    </React.Suspense>
  );
};
